.profile{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    max-height: 850px;
    min-height: 650px;
    border-radius: 2%;
    margin-top: 10px;
}

.profile-mobile{
    display: flex;
    flex-direction: column;
    justify-content: start;
    /*align-items: center;*/
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    max-height: 850px;
    min-height: 650px;
    border-radius: 2%;
    margin-top: 10px;
}

.container-get-profile{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    max-height: 800px;
    width: 100%;
}

.container-v-profile{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    max-height: 800px;
    background-color: rgba(28, 53, 88, 0.907);
    border-radius: 2%;
    height: 730px;
}

.text{
    color: white;
}

.personal-info{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

hr{
    border: none;
    height: 2px;
    color: #f8f8f8;
    background-color:  #f8f8f8;
}

.nav-bar-profile{
    display: flex;
    color: white;
    font-size: 20px;
    font-weight: bold;
    gap: 2rem;
}

.nav-bar-mobile{
    display: flex;
    justify-content: end;
    margin-right: 40px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    gap: 2rem;
    width: 100%;
}

.top-page{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(28, 53, 88, 0.907);
    gap: 8rem;
    padding-left: 20px;
    width: 100%;
    padding-right: 20px;
}

.top-page-mobile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}
.container-get-profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(28, 53, 88);
    width: 100%;
}

.container-get-profile-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(28, 53, 88);
    width: 100%;
}

.all-info-mobile{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
    border: 1px solid rgba(222, 222, 222, 0.56);
    border-radius: 5%;
    padding: 20px;
    gap: 2rem;
}

.scroll-treats-mobile{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 500px;
    width: 100%;
}

.item-info-mobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    margin: 5px;
}

.m-btn{
    background-color: transparent;
    border: 0px;
}

.menu-nav{
    background-color: rgba(26, 26, 26, 0.712);
    border-radius: 5%;
    width: 100%;
    padding: 20px;
    list-style: none;
}

.text-profile-name{
    color: rgb(169, 195, 232);
    font-size: 22px;
    font-weight: bold;
}

.nav-item-profile{
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.items-container-profile-mobile{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    height: 300px;
    width: 100%;
}

.container-payments-sessions{
    width: 100%;
}

.btn-add-container-profile{
    display: flex;
    justify-content: end;
    width: 100%;
    margin-bottom: 10px;
    gap: 1rem;
}

.btn-add-container-profile-mobile{
    display: flex;
    justify-content: end;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 20px;
    gap: 1rem;
}

.container-profile{
    width: 100%;
}