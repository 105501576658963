.ground{
    display: grid;
    grid-template-columns: auto auto;
    background-color: rgb(28, 53, 88);
    min-height: 750px;
    padding-left: 10px;
    padding-right: 10px;
}

.ground-mobile{
    display: flex;
    flex-direction: column;
    background-color: rgb(28, 53, 88);
    min-height: 760px;
    padding-left: 10px;
    padding-right: 10px;
}

.fk-mobile{
    width: 100%;
}

.back-tool-mobile{
    display: flex;
    justify-content: end;
    width: 100%;
    margin-bottom: 10px;
}

.ground-get{
    display: flex;
    justify-content: space-around;
    background-color: rgb(28, 53, 88);
    min-height: 730px;
}

.ground-get-mobile{
    display: flex;
    flex-direction: column;
    background-color: rgb(28, 53, 88);
    min-height: 760px;
}

.t-page{
    background-color: rgb(28, 53, 88);
    min-height: 730px;
    padding-top: 20px;
}

.nav-s{
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-bottom: 10px;
    margin-right: 100px;
}

.nav-s-mobile{
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-bottom: 10px;
}

.b{
    cursor: pointer;
    border:4px solid green;
    border-radius: 10%;
}

.g{
    cursor: pointer;
    border:4px solid yellow;
    border-radius: 10%;
}

.g-mobile{
    cursor: pointer;
    border:4px solid yellow;
    border-radius: 10%;
    width: 8%;
    max-width: 30px;
}

.b-mobile{
    cursor: pointer;
    border:4px solid green;
    border-radius: 10%;
    max-width: 30px;
    width: 8%;
}

.bb{
    cursor: pointer;
    border:3px solid rgb(30, 255, 0);
    border-radius: 2%;
}

.bb :active{
    border: 3px solid rgb(30, 255, 0);
    border-radius: 2%;
}

.b :active{
    border: 3px solid green;
    border-radius: 2%;
}

.t{
    cursor: pointer;
}

.t-mobile{
    cursor: pointer;
    max-width: 30px;
    width: 8%;
}

.h{
    display: flex;
    align-items: center;
    justify-content: center;
}

.h-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.hh{
    display: flex;
    gap: 20px;
    border-radius: 10px;
    color: white;
}

.hh-mobile{
      display: flex;
      justify-content: center;
      gap: 5px;
      border-radius: 10px;
      color: white;
}

.f-ss{
    display: flex;
    width: 100%;
    margin-top: 5px;
}

.f-sss{
    display: flex;
    width: 100%;
    margin-top: 5px;
}

.f-ssss{
    display: flex;
    width: 100%;
    margin-top: 5px;
}

.f-s{
    display: flex;
    width: 100%;
    margin-top: 5px;
}

.formm{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.sel{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: start;
    margin-right: 100px;
}

.sel-mobile{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: start;
}

.treat-details{
    width: 100%;
}

.label-text-head{
    color: white;
}

.label-text-cost{
    color: rgb(239, 239, 10);
    border: 1px solid rgb(28, 53, 88);
}

.label-text{
    font-weight: bold;
    color: white;
    margin-bottom: 1px;
    margin-top: 5px;
    /*border: 1px solid rgb(41, 77, 126);*/
}

.label-text-title{
    font-weight: bold;
    color: white;
    margin-bottom: 1px;
    margin-top: 10px;
    /*border: 1px solid rgb(41, 77, 126);*/
}

.h{
    display: flex;
    gap: 40px;
}

.h-mobile{
    display: flex;
    gap: 20px;
}

.l{
    display: flex;
    justify-content: center;
    gap: 40px;
}

.l-mobile{
    display: flex;
    justify-content: center;
    gap: 20px;
}

.fk{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 10px;
}

.hh-both{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.adult{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.kids{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.submit-m-btn{
    margin-top: 10px;
    width: 100%;
}

.treatments-container-in{
    display: grid;
    grid-template-columns: auto auto;
    /*border: 1px solid green;*/
    background-color: #ffd70714;
    width: 100%;
    /*margin-bottom: 5px;*/
}

.treatments-container-out{
    display: grid;
    grid-template-columns: auto auto;
    /*border: 1px solid red;*/
    background-color: #ffd70714;
    width: 100%;
   /* margin-bottom: 5px;*/
}

.treatments-container-mobile-in{
    display: grid;
    grid-template-columns: auto auto;
    /*border: 1px solid green;*/
    background-color: #ffd70714;
    width: 100%;
   /* margin-bottom: 5px;*/
}

.treatments-container-mobile-out{
    display: grid;
    grid-template-columns: auto auto;
   /* border: 1px solid red;*/
    background-color: #ffd70714;
    width: 100%;
    /*margin-bottom: 5px;*/
}

.treatments-tools-container-mobile-in{
    border: 1px solid green;
    width: 100%;
    margin-bottom: 5px;
}

.treatments-tools-container-in{
    border: 1px solid green;
    width: 100%;
    margin-bottom: 5px;
}

.treatments-tools-container-mobile-out{
    border: 1px solid red;
    width: 100%;
    margin-bottom: 5px;
}

.treatments-tools-container-out{
    border: 1px solid red;
    width: 100%;
    margin-bottom: 5px;

}

.tools-treat{
    display: flex;
    background-color: #ffd70714;
}

.head-treats-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 25px;
    padding-left: 10px;
}

.head-treats-list-container-in{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    border: 3px solid green;
    padding: 0px;
}

.head-treats-list-container-out{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    border: 3px solid red;
    padding: 0px;
}


.head-treats-container-mobile{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.head-treats-container-list-in-mobile{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    border: 3px solid green;
}

.head-treats-container-list-out-mobile{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    border: 3px solid red;
}

.treatstype-container{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 8px;
}

.treatstype-container-add{
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 255, 255, 0.71);
    padding: 20px;
    border-radius: 2%;
    width: 100%;
}

.btn-submit-back{
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 10px;
}

.btn-treats{
    width: 200px;
    border: 1px outset rgb(255, 255, 255);
    color: white;
    background-color: rgb(29, 54, 89);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.out{
    cursor: pointer;
    border: 4px solid red;
}

.out-mobile{
    cursor: pointer;
    border: 4px solid red;
    max-width: 30px;
    width: 8%;
}

.tooth-mobile{
    cursor: pointer;
    max-width: 30px;
    width: 8%;
}

.tooth-mobile:hover{
    border: 3px solid rgb(193, 193, 193);
}

.tools{
    display: flex;
    justify-content: start;
    gap: 4px;
}

.tools-up{
    display: flex;
    justify-content: end;
    gap: 4px;
}

.treat:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.46);
}

.treat:focus {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.46);
}

.gsrArray{
    display: flex;
    gap: 8px;
}

.gsrArray-mobile{
    display: flex;
    gap: 4px;
}

.delete-popup{
    display: flex;
    flex-direction: column;
}

.delete-popup-mobile{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.btns-popup{
    display: flex;
    justify-content: space-evenly;
}

.addTreatType{
    display: flex;
    margin: 2px;
    gap: 8px;
    width: 100%;
}

.addTreatType-mobile{
    display: flex;
    margin: 2px;
    gap: 4px;
    width: 100%;
}

.addTreatType-container{
    width: 100%;
}

.cannalAdd{
    display: flex;
    margin: 2px;
    gap: 4px;
}

.cannalAdd-mobile{
    display: flex;
    margin: 2px;
    gap: 2px;
}

.add-menu-btn{
    font-size: 14px;
    font-weight: bold;
    width: 60%;
}

.Pic-background{
    background-color: rgb(28, 53, 88);
    min-height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.img-pic{
    width: 100%;
    border: 2px solid white;
}

.btns-popup-mobile{
    display: flex;
    gap: 1rem;
}

.channals-names{
    display: grid;
    align-items: center;
    grid-template-columns: 50px auto;
    width: 100%;
}