/*@font-face {
    font-family: 'arabic-noto';
    src: url(../assets/fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-VariableFont_wght.ttf) format('truetype');
}*/

@font-face {
    font-family: 'lateef';
    src: url(../assets/fonts/Lateef/Lateef-ExtraBold.ttf) format('truetype');
}

.header-container{
    display: flex;
    justify-content: space-between;
    background-color: black;
    height: 75px;
}

.header-container-mobile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    height: 75px;
}

.logo{
    height: 28px;
    margin-left: 20px;
    border-radius: 2%;
    margin-bottom: 5px;
}

.logoPic-img{
    width: 80px;
    margin-right: 20px;
    border-radius: 2%;
    margin-bottom: 5px;
}

.logoPic-img-mobile{
    width: 25px;
    border-radius: 2%;
}

.logo-mobile{
    width: 40%;
    margin-left: 20px;
    margin-bottom: 5px;
}

.header{
    display: flex;
    align-items: center;
    justify-content: start;
    background: rgba(255, 255, 255, 0);
    width: 30%;
    gap: 1rem;
}

.title{
    display: flex;
    font-family: 'lateef',sans-serif;
    font-weight: bold;
    font-size: 35px;
    /*font-family: Lobster-Regular, 'Courier New', Courier, monospace;*/
    color: white;
    margin-right: 15px;
}

.title-mobile{
    display: flex;
    font-family: 'lateef',sans-serif;
    font-weight: bold;
    font-size: 26px;
    /*font-family: Lobster-Regular, 'Courier New', Courier, monospace;*/
    color: white;
}

.title-container{
    display: flex;
    align-items: center;
    /*margin-right: 10px;*/
    gap: 0.5rem;
}

.header-search{
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}