.container-send{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 500px;
    gap: 1rem;
    color: white;
}

.container-send-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    gap: 1rem;
    color: white;
}

.renew-form{
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 500px;
    padding: 20px;
    gap: 1rem;
    color: white;
}

.renew-form-mobile{
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    padding: 20px;
    gap: 1rem;
    color: white;
}

.form-submit{
    display: flex;
    width: 500px;
    flex-direction: column;
    gap: 1rem;
}

.form-submit-mobile{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
}

.submit-btn-container{
    display: flex;
    justify-content: end;
    width: 500px;
}

.submit-btn-container-mobile{
    display: flex;
    justify-content: end;
    width: 100%;
}

.submit-btn{
    width: 200px;
}

.submit-container{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.submit-container-mobile{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.input-form-mobile{
    width: 100%;
}