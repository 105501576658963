.login-page{
    height: 730px;
}

.login-page-mobile{
    height: 760px;
    width: 100%;
}

.login-form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: start;
    width: 500px;
}

.login-form-mobile{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: start;
    width: 100%;
}

.login-container{
    padding: 20px;
    border: 1px solid rgb(176, 173, 173);
    border-radius: 2%;
    background-color: rgba(28, 53, 88, 0.767);
}

.login-container-mobile{
    padding: 20px;
    border: 1px solid rgb(176, 173, 173);
    border-radius: 2%;
    background-color: rgba(28, 53, 88, 0.767);
    width: 100%;
}

.container{
    display: flex;
    justify-content: end;
    align-items: end;
    height: 600px;
}

.rest-send{
    display: flex;
    justify-content: end;
    align-items: end;
    padding: 20px;
    border: 1px solid rgb(176, 173, 173);
    border-radius: 2%;
    background-color: rgba(28, 53, 88, 0.767);
    width: 600px;
}

.rest-send-mobile{
    display: flex;
    justify-content: end;
    align-items: end;
    padding: 20px;
    border: 1px solid rgb(176, 173, 173);
    border-radius: 2%;
    background-color: rgba(28, 53, 88, 0.767);
    width: 100%;
}

.btn-forgot{
    display: flex;
    justify-content: end;
}

.login-btn{
    width: 200px;
}

.forgot-link{
    text-decoration: underline;
    cursor: pointer;
}

.title-login{
    color: white;
}

.login-btn-container{
    display: flex;
    justify-content: end;
    width: 500px;
}

.login-btn-container-mobile{
    display: flex;
    justify-content: end;
    width: 100%;
}