.container-add{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 730px;
}

.container-add-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.title-add{
    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
}

.title-add-mobile{
    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    width: 100%;
}

.add-form{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 40px;
    margin-top: 20px;
    gap: 1rem;
    border: 1px solid rgb(176, 173, 173);
    border-radius: 2%;
    background-color: rgba(28, 53, 88, 0.907);
}

.add-form-mobile{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 40px;
    gap: 1rem;
    border: 1px solid rgb(176, 173, 173);
    background-color: rgba(28, 53, 88, 0.941);
    width: 100%;
    min-height: 760px;
}

.form-line{
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.form-line-label{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.7rem;
}

.form-lines{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.form-input{
    font-size: 18px;
    width: 800px;
    height: 50px;
}

.form-input-mobile{
    font-size: 18px;
    width: 100%;
    height: 50px;
}

.form-input-phone::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-input-area{
    font-size: 18px;
    width: 800px;
    height: 80px;
}

.form-input-area-mobile{
    font-size: 18px;
    width: 100%;
    height: 80px;
}

.add-button{
    font-size: 18px;
    font-weight: bold;
}

.tooth-img{
    width: 100px;
}

.tooth-img-mobile{
    width: 30%;
}

.btn-container{
    display: flex;
    justify-content: end;
    width: 800px;
    gap: 2rem;
}

.btn-container-mobile{
    display: flex;
    justify-content: end;
    width: 100%;
}

.session-container{
    height: 730px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.add-form-session{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 40px;
    margin: 20px;
    gap: 1rem;
    border: 1px solid rgb(176, 173, 173);
    border-radius: 2%;
    background-color: rgba(28, 53, 88, 0.907);
}

.add-form-treats{
    border: 1px solid #b0adad;
    border-radius: 2%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    margin-top: 10px;
    padding: 40px;
    background-color: rgba(28, 53, 88, 0.907);
}