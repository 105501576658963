.btn-add{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 50%;
}

.btn-add-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 50%;
}

.container-v{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 40px;
    width: 100%;
    max-height: 730px;
    background-color: rgba(28, 53, 88, 0.781);
}

.container-pages{
    height: 730px;
    max-height: 730px;
    width: 100%;
}

.container-pages-smallLap{
    height: 730px;
    max-height: 730px;
    width: 100%;
}

.container-pages-mobile{
    height: 730px;
    max-height: 730px;
    width: 100%;
}

.container-v-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    min-height: 760px;
    max-height: 760px;
    background-color: rgb(28, 53, 88);
}

/*.container-profile{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 1400px;
    border-radius: 2%;
    margin-bottom: 20px;
}*/

.container-profile-mobile{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    margin-bottom: 20px;
}

.items-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    height: 500px;
    width: 100%;
    border: 1px solid rgba(27, 27, 70, 0.793);
}

.items-container-smallLap{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    height: 500px;
    width: 100%;
    border: 1px solid rgba(27, 27, 70, 0.793);
}

.items-container-mobile{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    width: 100%;
    height: 500px;
}

.container-get{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    max-height: 760px;
    width: 100%;
}

.btn-add-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    gap: 1rem;
}

.btn-add-container-smallLap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    gap: 1rem;
}

.btn-add-container-mobile{
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    margin-bottom: 10px;
    gap: 1rem;
}


.btn-add-container-control{
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    gap: 1rem;
}

.btn-add-container-control-mobile{
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    gap: 1rem;
}

.btn-container-mobile{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    gap: 1rem;
}

.table-cell{
    border-collapse: collapse;
    background-color: rgba(43, 77, 126, 0.97);
    width: 100%;
}

.table-cell-mobile{
    border-collapse: collapse;
    background-color: rgb(43, 78, 126);
    font-size: 12px;
}

.table-cell-mobile-personal{
    border-collapse: collapse;
    background-color: rgba(43, 78, 126, 0);
    width: 100%;
    display: flex;
    justify-content: center;
}

.table-cell tr:nth-child(even){background-color: rgba(25, 69, 134, 0.97);}

.table-cell tr:hover {background-color: #2dad49b8;}

.table-cell tr::selection {background-color: #2dad49b8;}

.table-cell-mobile tr:nth-child(even){background-color: rgba(25, 69, 134, 0.97);}

.table-cell-mobile tr:hover {background-color: #2dad49b8;}

.table-cell-mobile tr:focus-visible {background-color: #232732dd;}

.table-cell-mobile tr::selection {background-color: #2dad49b8;}

.table-cell-mobile-personal tr:nth-child(even){background-color: rgba(25, 69, 134, 0.97);}

.table-cell-mobile-personal tr:hover {background-color: #232732dd;}

.table-cell-mobile-personal tr::selection {background-color: #c6cdd6b8;}

.table-cell th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #21232b;
    color: white;
}

.item-table-title-mobile{
    background-color: #21232b;
}

.table-cell-mobile .th-mobile {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #18191b;
    color: white;
}

.table-cell-mobile-personal .th-mobile {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #18191b;
    color: white;
}

.table-cell td, .table-cell th {
    border: 1px solid rgb(28, 53, 88);
    color: white;
    padding: 8px;
}

.table-cell-mobile td, .table-cell-mobile th {
    border: 1px solid rgb(14, 15, 15);
    color: white;
    padding: 8px;
    flex-wrap: wrap
}

.table-cell-mobile-personal td, .table-cell-mobile th {
    border: 1px solid rgb(14, 15, 15);
    color: white;
    padding: 8px;
    flex-wrap: wrap
}

.table-cell tr:focus {background-color: #232732dd;}

.table-cell tr:active {background-color: #232732dd;}

.search-form{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    width: 600px;
}

.search-form-mobile{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
}

.item-btns{
    display: flex;
    justify-content: end;
    gap: 1rem;
}

.item-btns-mobile{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 1rem;
    width: 100%;
}

.amount-text{
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.results{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.results-mobile{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.item-table{
    cursor: pointer;
}

.item-table-mobile{
    cursor: pointer;
}

.btn-edit{
    margin-top: 10px;
}

.data-control{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.data-control-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.container-get-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.search-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.search-container{
    display: flex;
    align-items: center;
}

.treat-num-text{
    font-size: 20px;
    font-weight: bold;
}

.text-path{
    color: white;
    display: flex;
    justify-content: end;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
}

.item-btns-nav-mobile{
    display: flex;
    justify-content: end;
    gap: 1rem;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btns-controls{
    display: flex;
    gap: 1rem;
}

.btns-controls-mobile{
    display: flex;
    gap: 1rem;
}

.nav-bar-patients{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
}

.nav-bar-patients-mobile{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
}

.item-treat{
    display: flex;
    gap: 1rem;
}

.add-btnsInMenu{
    display: flex;
    justify-content: center;
    gap: 5px;
}