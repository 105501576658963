.logo-container{
    display: flex;
    justify-content: end;
}

.logo-img{
    position: fixed;
    bottom: 0;
    padding-bottom: 80px;
}

.logo-company{
    width: 80px;
}