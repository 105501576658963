.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to right, rgba(28, 53, 88, 0.941), rgb(90, 128, 177));
    border: 1px solid rgb(184, 184, 184);
    justify-content: center;
}

.card-body{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    width: 1380px;
    color: white;
    height: 80px;
}

.btn-details{
    margin-left: 20px;
}

td{
    border: 1px solid #ddd;
    padding: 8px;
  }